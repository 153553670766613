
import { defineComponent } from "vue";

import { useKoruConfig } from "@/core/modules/config";

export default defineComponent({
  name: "KoruLoading",
  setup() {
    const koruConfig = useKoruConfig();

    return {
      koruConfig,
    };
  },
});
