import { UserInterface } from "@/core/modules/user/models/User.interface";
import { MutationTree } from "vuex";
import { AuthStateInterface } from "../models/AuthState.interface";
import { authStoreTypes } from "./types";

export const mutations: MutationTree<AuthStateInterface> = {
  [authStoreTypes.mutations.loadingStart]: (state: AuthStateInterface): void => {
    state.loading = true;
  },
  [authStoreTypes.mutations.loadingStop]: (state: AuthStateInterface): void => {
    state.loading = false;
  },
  [authStoreTypes.mutations.loginSuccess]: (state: AuthStateInterface, user: UserInterface): void => {
    state.isLoggedIn = true;
    state.user = user;
    state.loading = false;
  },
  [authStoreTypes.mutations.loginError]: (state: AuthStateInterface): void => {
    state.isLoggedIn = false;
    state.user = undefined;
    state.loading = false;
  },
  [authStoreTypes.mutations.logoutSuccess]: (state: AuthStateInterface): void => {
    state.isLoggedIn = false;
    state.user = undefined;
    state.loading = false;
  },
};
