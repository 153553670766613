import { koruConfig } from "@/core/modules/config";
import { firebaseStorage } from "./firebase-storage";
import { StorageInterface } from "./models/Storage.interface";

let storage: StorageInterface;
if (koruConfig.storage.type === "live") {
  storage = firebaseStorage;
} else {
  throw Error("Invalid or undefined config.storage.type");
}

export const koruStorage = storage;

export const useKoruStorage = (): StorageInterface => {
  return koruStorage;
};
