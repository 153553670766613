import { RouteRecordRaw } from "vue-router";

export const profileRoutes: Array<RouteRecordRaw> = [
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRights: [],
    },
  },
];
