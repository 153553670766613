import { RouteRecordRaw } from "vue-router";

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/Forgot.vue"),
  },
  {
    path: "/recover",
    name: "Recover",
    component: () => import("../views/Recover.vue"),
  },
];
