export const enPrimevue = {
  startsWith: "Starts with",
  contains: "Contains",
  notContains: "Not contains",
  endsWith: "Ends with",
  equals: "Equals",
  notEquals: "Not equals",
  noFilter: "No Filter",
  lt: "Less than",
  lte: "Less than or equal to",
  gt: "Greater than",
  gte: "Greater than or equal to",
  dateIs: "Date is",
  dateIsNot: "Date is not",
  dateBefore: "Date is before",
  dateAfter: "Date is after",
  clear: "Clear",
  apply: "Apply",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Add Rule",
  removeRule: "Remove Rule",
  accept: "Yes",
  reject: "No",
  choose: "Choose",
  upload: "Upload",
  cancel: "Cancel",
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  today: "Today",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "mm/dd/yy",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  passwordPrompt: "Enter a password",
  emptyFilterMessage: "No results found",
  emptyMessage: "No available options",
};
