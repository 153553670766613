export const itPrimevue = {
  startsWith: "Inizia con",
  contains: "Contiene",
  notContains: "Non contiene",
  endsWith: "Finisce con",
  equals: "Uguale",
  notEquals: "Non uguale",
  noFilter: "Nessun Filtro",
  lt: "Minore di",
  lte: "Minore di o uguale a",
  gt: "Maggiore di",
  gte: "Maggiore di o uguale a",
  dateIs: "La data è",
  dateIsNot: "La data non è",
  dateBefore: "Data è precedente",
  dateAfter: "Data è successiva",
  clear: "Pulisci",
  apply: "Applica",
  matchAll: "Soddisfa tutti",
  matchAny: "Soddisfa qualsiasi",
  addRule: "Aggiungi regola",
  removeRule: "Rimuovi regola",
  accept: "Sì",
  reject: "No",
  choose: "Scegli",
  upload: "Carica",
  cancel: "Cancella",
  dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
  monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
  monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
  today: "Oggi",
  weekHeader: "Sett.",
  firstDayOfWeek: 1,
  dateFormat: "dd/mm/yy",
  weak: "Debole",
  medium: "Media",
  strong: "Forte",
  passwordPrompt: "Inserisci una password",
  emptyFilterMessage: "Nessun risultato trovato",
  emptyMessage: "Nessuna opzione disponibile",
};
