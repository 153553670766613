import { createStore, StoreOptions } from "vuex";

import { KoruStateInterface } from "./models/KoruState.interface";
import { KoruStoreModel } from "./models/KoruStore.model";

import { initialState } from "./initial-state";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

const storeOptions: StoreOptions<KoruStateInterface> = {
  state: initialState,
  actions: actions,
  getters: getters,
  mutations: mutations,
};

export const koruStore: KoruStoreModel<KoruStateInterface> = createStore<KoruStateInterface>(storeOptions);

export const useKoruStore = (): KoruStoreModel<KoruStateInterface> => {
  return koruStore;
};

export function retrieveModuleGetter<T>(moduleName: string, getterName: string): T {
  return koruStore.getters[`${moduleName}/${getterName}`];
}

export function dispatchModuleAction<T>(moduleName: string, actionName: string, params?: T): Promise<unknown> {
  return koruStore.dispatch(`${moduleName}/${actionName}`, params);
}

export function commitModuleMutation<T>(moduleName: string, mutationName: string, params?: T): void {
  koruStore.commit(`${moduleName}/${mutationName}`, params);
}
