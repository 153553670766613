import { RouteRecordRaw } from "vue-router";

export const installerRoutes: Array<RouteRecordRaw> = [
  {
    path: "/installer",
    name: "Installer",
    component: () => import("../views/Installer.vue"),
    meta: {
      requiresAuth: false,
      requiredModule: "",
      requiredRights: [],
    },
  },
];
