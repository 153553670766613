
import { defineComponent } from "vue";

import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";

export default defineComponent({
  name: "Footer",
  setup() {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();

    return {
      koruConfig,
      t,
    };
  },
});
