export interface UserDetailInterface {
  locale: string;
  theme: string;
}

export function userDetailFromFirestore(data: Record<string, unknown>): UserDetailInterface {
  return {
    locale: data.locale as string,
    theme: data.theme as string,
  };
}

export function userDetailToFirestore(data: UserDetailInterface): Record<string, unknown> {
  return {
    locale: data.locale || null,
    theme: data.theme || null,
  };
}
