
import { computed, defineComponent } from "vue";
import { google } from "google-maps";
import { Loader } from "@googlemaps/js-api-loader";

import { uniqueId } from "@/core/plugins/unique-id";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";

export default defineComponent({
  name: "KoruPlaces",
  emits: ["update:show", "update:address", "update:town", "update:zipCode", "update:province"],
  props: {
    show: { type: Boolean, default: false },
    address: { type: String, default: "" },
    town: { type: String, default: "" },
    zipCode: { type: String, default: "" },
    province: { type: String, default: "" },
  },
  setup(props) {
    const koruConfig = useKoruConfig();
    const { t } = useKoruLocale();

    const isVisible = computed(() => {
      return props.show;
    });

    const elementId = "hints-field-" + uniqueId();

    return {
      elementId,
      koruConfig,
      isVisible,
      t,
    };
  },
  methods: {
    init() {
      const loader = new Loader({
        apiKey: this.koruConfig.mapsApiKey,
        version: "weekly",
        libraries: ["places"],
      });

      loader.load().then(() => {
        const addressField = document.querySelector("#" + this.elementId) as HTMLInputElement;
        const autocomplete = new window.google.maps.places.Autocomplete(addressField, {
          componentRestrictions: { country: ["it"] },
          fields: ["address_components"],
        });
        addressField.focus();
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          this.$emit("update:address", "");
          this.$emit("update:town", "");
          this.$emit("update:zipCode", "");
          this.$emit("update:province", "");
          let streetNumber = "";

          for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0];
            switch (componentType) {
              case "street_number":
                streetNumber = ", " + component.long_name;
                break;
              case "route":
                this.$emit("update:address", component.long_name + streetNumber);
                break;
              case "administrative_area_level_3":
                this.$emit("update:town", component.long_name);
                break;
              case "postal_code":
                this.$emit("update:zipCode", component.long_name);
                break;
              case "administrative_area_level_2":
                this.$emit("update:province", component.short_name);
                break;
            }
          }
          addressField.value = "";
          this.close();
        });
      });
    },
    close() {
      this.$emit("update:show", false);
    },
  },
});
