import { koruConfig } from "@/core/modules/config";

import { AuthInterface } from "./models/Auth.interface";
import { firebaseAuth } from "./firebase-auth";

let auth: AuthInterface;
if (koruConfig.authentication.type === "live") {
  auth = firebaseAuth;
} else {
  throw Error("Invalid or undefined config.authentication.type");
}

export const koruAuth = auth;
